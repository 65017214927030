<template>
  <div class="tl-grade-dist">
    <v-row v-if="anyDesired" no-gutters class="mb-2 flex-nowrap">
      <v-col cols="auto" class="tl-grade-dist__grade" />
      <v-col cols="auto" class="tl-grade-dist__count-val">
        <span class="tl-grade-dist__indicator mx-1 primary" />
      </v-col>
      <v-col cols="auto" class="grow pl-1">
        {{ $vuetify.breakpoint.xs ? 'Nr of' : 'Number of' }} {{ climbTypeLocal }}s
      </v-col>
      <v-col cols="auto">
        <span class="tl-grade-dist__indicator mr-1 grey darken-1" />Desired{{ $vuetify.breakpoint.xs ? '' : ' amount' }}
      </v-col>
    </v-row>
    <v-row v-for="grade in gradesWithCountsStripped" :key="grade.name" align="center" no-gutters>
      <v-col cols="auto" class="tl-grade-dist__grade" v-html="grade.name" />
      <v-col v-if="anyDesired" cols="auto" class="tl-grade-dist__count-val" v-text="grade.count" />
      <v-col class="tl-grade-dist__bars-container d-flex flex-column">
        <div class="tl-grade-dist__count-bar grow primary" :style="{ width: getBarWidth(grade.count) }" />
        <div
          v-if="anyDesired"
          class="tl-grade-dist__count-bar grow grey darken-1"
          :style="{ width: getBarWidth(grade.countDesired) }"
        />
      </v-col>
      <v-col v-if="!anyDesired" cols="auto" class="tl-grade-dist__count-val" v-text="grade.count" />
      <v-col v-if="anyDesired" cols="auto" class="d-flex justify-center align-center">
        <v-btn v-if="editable" x-small icon height="20" @click="updateDesired(grade, false)">
          <v-icon color="grey darken-4">tl-remove</v-icon>
        </v-btn>
        <span class="text-right" :class="{ 'text-center': editable }" style="width: 25px">
          {{ grade.countDesired }}
        </span>
        <v-btn v-if="editable" x-small icon height="20" @click="updateDesired(grade, true)">
          <v-icon color="grey darken-4">tl-add</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Grade from '@/services/grades/Grade'

export default {
  props: {
    climbType: { type: String, default: '' },
    counts: { type: Array, default: () => [] },
    countsUsers: { type: Array, default: () => [] },
    countsDesired: { type: Array, default: () => [] },
    level: { type: Number, default: 1 },
    hideZeros: { type: Boolean, default: true },
    editable: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({ climbTypeStore: 'climbType' }),
    climbTypeLocal() {
      return this.climbType || this.climbTypeStore
    },
    anyDesired() {
      return this.countsDesired.length
    },
    grades() {
      return Grade.system(this.climbTypeLocal)
        .data.filter(g => g.level <= this.level)
        .map(g => ({ value: g.value, name: g[`name${this.level}`] || g.name }))
    },
    gradesWithCounts() {
      return this.grades.map((grade, index) => {
        let nextGrade = this.grades[index + 1] || { value: 1000 }
        grade.count = this.getGradeCount({ counts: this.counts, grade, nextGrade })
        grade.countUsers = this.getGradeCount({ counts: this.countsUsers, grade, nextGrade })
        grade.countDesired = this.getGradeCount({ counts: this.countsDesired, grade, nextGrade })
        return grade
      })
    },
    unknownCount() {
      return this.gradesWithCounts[0]
    },
    gradesWithCountsStripped() {
      let unknownCount = this.gradesWithCounts[0]
      let gradesStripped = this.gradesWithCounts.slice(1) // Remove unknow count
      if (this.hideZeros) {
        const iStart = gradesStripped.findIndex(grade => grade.count || grade.countUsers || grade.countDesired) // eslint-disable-next-line
        const iEnd = gradesStripped.length - gradesStripped
            .slice()
            .reverse()
            .findIndex(grade => grade.count || grade.countUsers || grade.countDesired)
        gradesStripped = gradesStripped.slice(iStart, iEnd)
      }
      unknownCount.name = '?'
      gradesStripped.push(unknownCount)
      return gradesStripped
    },
    maxCount() {
      const maxCounts = this.gradesWithCountsStripped
        .filter(g => g.value > 2.0)
        .map(g => {
          return Math.max(g.count || 0, g.countDesired || 0, g.countUsers || 0)
        })
      return Math.max.apply(Math, maxCounts)
    },
  },
  methods: {
    getGradeCount({ counts, grade, nextGrade }) {
      return this.parseCounts(counts)
        .filter(toCount => toCount.value >= grade.value && toCount.value < nextGrade.value)
        .reduce((sum, toCount) => sum + toCount.count, 0)
    },
    parseValue(value) {
      return new Grade(value, this.climbTypeLocal).valueRounded
    },
    parseCounts(counts) {
      // Parses the values into rounded grades
      return counts.map(item => {
        let value = this.parseValue(item.value)
        let count = item.count == null ? 1 : item.count
        return { value, count }
      })
    },
    getBarWidth(count) {
      return (Math.min(Math.max(count, 0), this.maxCount) / this.maxCount) * 100 + '%'
    },
    updateDesired(grade, add) {
      let countsDesired = JSON.parse(JSON.stringify(this.countsDesired))
      let desiredGrade = countsDesired.find(desired => grade.value == this.parseValue(desired.value))
      if (!desiredGrade) {
        desiredGrade = { value: grade.value, count: 0 }
        countsDesired.push(desiredGrade)
        countsDesired.sort((a, b) => a.value - b.value)
      }
      desiredGrade.count = add ? desiredGrade.count + 1 : desiredGrade.count - 1
      this.$emit('update-desired', countsDesired)
    },
  },
}
</script>

<style lang="sass">
.tl-grade-dist
  white-space: nowrap
  line-height: normal
  &__grade
    width: 35px
  &__bars-container
    height: 20px
    margin: 2px 5px
  &__count-bar
    margin: 1px 0
    border-top-right-radius: 4px
    border-bottom-right-radius: 4px
    transition: width 300ms
  &__count-val,
  &__count-val--desired
    width: 15px
    margin-left: 5px
    text-align: right
  &__count-val--desired
    width: 35px
  &__indicator
    display: inline-block
    width: 10px
    height: 10px
    border-radius: 10px
</style>
