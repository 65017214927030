<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="auto">
        <v-card max-width="1000" :loading="loading" class="pb-4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="headline">Average star-ratings</v-list-item-title>
              <v-list-item-subtitle>
                Get insight in popularity, based on the average star-rating given by clients.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-text>
            <span><b>How it works</b></span>
            <p>
              Relative rating scales the lowest-scoring entity to 0, and the highest to 100% bar width.<br />
              Absolute rating scales the bars between 0 stars (0%) and 5 stars (100%).<br />
              Darker colors indicates the statistics are less significant, i.e. they are based on relatively few
              star-votes.
            </p>
          </v-card-text>

          <div v-if="gym.nr_of_routes && gym.nr_of_boulders" class="d-flex align-center flex-wrap mx-4">
            <span class="text--bold">Type</span>
            <v-spacer />
            <v-btn-toggle v-model="climbTypeLocal" tile group color="primary">
              <v-btn value="route">Routes</v-btn>
              <v-btn value="boulder">Boulders</v-btn>
            </v-btn-toggle>
          </div>

          <div class="d-flex align-center flex-wrap mx-4">
            <span class="text--bold">Scale</span>
            <v-spacer />
            <v-btn-toggle v-model="scale" tile group color="primary">
              <v-btn value="relative">Relative</v-btn>
              <v-btn value="absolute">Absolute</v-btn>
            </v-btn-toggle>
          </div>

          <tl-stats-popularity-card
            v-for="(data, entity) in stats"
            :key="entity"
            :entity="entity"
            :stats="data"
            :scale="scale"
            :climb-type="climbTypeLocal"
            class="ma-4"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Gym from '@/models/Gym'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import gymStatsPageMixin from '../gym-stats-page.mixin'
import tlStatsPopularityCard from './tl-stats-popularity-card'

export default {
  components: {
    tlStatsPopularityCard,
  },
  mixins: [toolbarMixin, gymStatsPageMixin],
  data: () => ({
    loading: false,
    stats: [],
    scale: 'relative',
    climbTypeLocal: 'boulder',
  }),
  computed: {
    ...mapState(['gym', 'climbType']),
    fetchParams() {
      return {
        gym_id: this.gym.id,
        climbs_type: this.climbTypeLocal,
      }
    },
  },
  created() {
    this.climbTypeLocal = this.climbType
    this.fetch()
  },
  watch: {
    fetchParams() {
      this.fetch()
    },
  },
  methods: {
    async fetch() {
      if (!this.gym.nr_of_climbs) return
      this.loading = true
      this.stats = await Gym.$apiCall('popularityStats', { params: this.fetchParams, timeout: 0 }).finally(
        () => (this.loading = false)
      )
    },
  },
}
</script>
