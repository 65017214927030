import { mapState } from 'vuex'

export default {
  data: () => ({
    tlToolbarTitle: 'Statistics',
  }),
  computed: {
    ...mapState(['gym']),
    tlToolbarTabs() {
      return [ // eslint-disable-line
        {
          name: 'Boulders',
          to: { name: 'gym.stats.climbs', params: { climbsType: 'boulders' } },
          hide: !this.gym.nr_of_boulders,
        },
        {
          name: 'Routes',
          to: { name: 'gym.stats.climbs', params: { climbsType: 'routes' } },
          hide: !this.gym.nr_of_routes,
        },
        { name: 'Popularity', to: { name: 'gym.stats.popularity' } },
        { name: 'Setters', to: { name: 'gym.stats.setters' } },
        { name: 'Usage', to: { name: 'gym.stats.usage' } },
      ].filter(tab => !tab.hide)
    },
  },
}
