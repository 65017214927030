<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="8" lg="6">
        <tl-stats-usage-history />
      </v-col>
      <v-col cols="12" sm="8" offset-sm="2" md="4" offset-md="0" lg="3">
        <tl-stats-usage-active />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import gymStatsPageMixin from '../gym-stats-page.mixin'
import tlStatsUsageHistory from './tl-stats-usage-history'
import tlStatsUsageActive from './tl-stats-usage-active'

export default {
  mixins: [toolbarMixin, gymStatsPageMixin],
  components: {
    tlStatsUsageHistory,
    tlStatsUsageActive,
  },
}
</script>
