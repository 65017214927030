<template>
  <v-card :loading="loading">
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">Currently active Users</v-list-item-title>
        <v-list-item-subtitle>Listing of users active last month</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list>
      <v-list-item v-for="user in usersPaginated" :key="user.number">
        <v-list-item-action class="mr-0 justify-center" v-text="user.number" />
        <v-list-item-content>
          <v-list-item-title>{{ user.first_name }} {{ user.last_name }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-text="user.ascends_count" />
      </v-list-item>
    </v-list>

    <tl-pagination-local :list="users" @list-paginated="usersPaginated = $event" :items-per-page="12" start-at-end />
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import User from '@/models/User'
import tlPaginationLocal from '@/components/shared/tl-pagination-local'

export default {
  components: {
    tlPaginationLocal,
  },
  data: () => ({
    loading: false,
    users: [],
    usersPaginated: [],
  }),
  computed: {
    ...mapState(['gym']),
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.loading = true
      let params = { gym_id: this.gym.id, period: 'month' }
      this.users = await User.$apiCall('findWithAscendsCount', { params, timeout: 0 }).finally(
        () => (this.loading = false)
      )
      this.users.forEach((user, index) => (user.number = index + 1))
    },
  },
}
</script>
