<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="auto">
        <v-card max-width="600" :loading="loading">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="headline">Grade distributions</v-list-item-title>
              <v-list-item-subtitle>
                Get insight in the number of climbs you have for each grade or group of grades.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-text>
            <span><b>How it works</b></span>
            <p>
              Click the 'add graph' button to create a chart, next click the 'edit' button to customize. By default it
              concerns all {{ this.climbType }}s in the gym. You can customize the region of climbs for this graph by
              selecting walls. This gives you an insight in possible grade-gaps in your gym.
            </p>
          </v-card-text>

          <v-expand-transition>
            <div class="d-flex align-center flex-wrap mx-4" v-if="regions.length">
              <span class="text--bold">Grade grouping</span>
              <v-spacer />
              <v-btn-toggle v-model="groupingLevel" tile group mandatory color="primary">
                <v-btn v-for="item in gradeGroups" :key="item.value" :value="item.value">{{ item.name }}</v-btn>
              </v-btn-toggle>
            </div>
          </v-expand-transition>

          <tl-stats-grade-dist
            v-for="region in regions"
            :key="region.id"
            :region="region"
            :level="groupingLevel"
            class="ma-4"
            @changed="fetch"
          />

          <v-divider />
          <v-card-actions>
            <v-btn color="primary" :loading="addingGraph" :disabled="addingGraph" @click="addGraph">
              Add graph
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Region from '@/models/Region'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import gymStatsPageMixin from '../gym-stats-page.mixin'
import climbsTypeGuard from '@/components/gym/climbs/climbs-type.guard'
import tlStatsGradeDist from './tl-stats-grade-dist'

export default {
  components: {
    tlStatsGradeDist,
  },
  mixins: [toolbarMixin, gymStatsPageMixin],
  data: () => ({
    loading: false,
    addingGraph: false,
    groupingLevel: 2,
    gradeGroups: [ // eslint-disable-line
      { value: 2, name: 'No grouping' },
      { value: 1, name: 'Medium' },
      { value: 0, name: 'Rough' },
    ],
  }),
  computed: {
    ...mapState(['gym', 'climbType']),
    climbsType() {
      return this.climbType + 's'
    },
    regions() {
      return this.gym.regions
        .filter(region => !region.deleted && region.climbs_type == this.climbsType)
        .slice()
        .sort((a, b) => a.order - b.order || a.created_at - b.created_at)
    },
  },
  created() {
    this.fetch()
  },
  beforeRouteEnter: climbsTypeGuard,
  beforeRouteUpdate: climbsTypeGuard,
  watch: {
    climbType() {
      this.fetch()
    },
  },
  methods: {
    async fetch() {
      this.loading = true
      await Region.$apiAll({
        params: {
          gym_id: this.gym.id,
          include_grades: true,
          json_params: {
            filters: { climbs_type: this.climbsType },
            includes: 'region_walls',
          },
        },
      }).finally(() => (this.loading = false))
    },
    addGraph() {
      this.addingGraph = true
      Region.$apiCreate({ gym_id: this.gym.id, climbs_type: this.climbsType })
        .then(this.fetch)
        .finally(() => (this.addingGraph = false))
    },
  },
}
</script>
