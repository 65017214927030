<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="auto">
        <v-card>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="headline">Setter stats</v-list-item-title>
              <v-list-item-subtitle>Number of climbs set by each setter</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <div v-if="gym.nr_of_routes && gym.nr_of_boulders" class="d-flex align-center flex-wrap mx-4">
            <span class="text--bold">Type</span>
            <v-spacer />
            <v-btn-toggle v-model="climbTypeLocal" tile group color="primary">
              <v-btn value="route">Routes</v-btn>
              <v-btn value="boulder">Boulders</v-btn>
            </v-btn-toggle>
          </div>

          <v-card-text>
            <p v-if="!gym.nr_of_climbs">There are no setter stats yet...</p>
            <v-card dark class="grey darken-4 setter-stats" :loading="loading">
              <table class="ma-4">
                <tr>
                  <th></th>
                  <th v-for="(header, index) in stats.headers" :key="index">
                    <span class="setter-stats__header" v-text="header" />
                  </th>
                </tr>
                <tr v-for="(row, index) in stats.rows" :key="index">
                  <td v-text="row.name" />
                  <td
                    v-for="(td, indexRow) in row.counts"
                    :key="indexRow"
                    :style="tdStyle(td)"
                    v-text="td.count || ''"
                  />
                </tr>
              </table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Gym from '@/models/Gym'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import gymStatsPageMixin from '../gym-stats-page.mixin'

export default {
  mixins: [toolbarMixin, gymStatsPageMixin],
  data: () => ({
    loading: false,
    stats: [],
    scale: 'relative',
    climbTypeLocal: 'boulder',
  }),
  computed: {
    ...mapState(['gym', 'climbType']),
    fetchParams() {
      return {
        gym_id: this.gym.id,
        climbs_type: this.climbTypeLocal,
      }
    },
  },
  created() {
    this.climbTypeLocal = this.climbType
    this.fetch()
  },
  watch: {
    fetchParams() {
      this.fetch()
    },
  },
  methods: {
    async fetch() {
      if (!this.gym.nr_of_climbs) return
      this.loading = true
      this.stats = await Gym.$apiCall('setterStats', { params: this.fetchParams }).finally(() => (this.loading = false))
    },
    tdStyle(td) {
      return { 'background-color': this.getBackgroundColor(td.relative_count) }
    },
    getBackgroundColor(relativeCount) {
      if (!relativeCount) return 'gray'
      return `rgba(255,0,148,${relativeCount})`
    },
  },
}
</script>

<style lang="sass">
.setter-stats
  overflow-x: scroll
  table
    margin-right: 60px
  th
    position: relative
    height: 120px
    text-align: start
    vertical-align: bottom
    padding-bottom: 10px
  &__header
    position: absolute
    transform: rotate(-60deg)
    transform-origin: left top
    width: 140px
    font-weight: normal
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  td
    padding: 3px
    min-width: 24px
    text-align: center
    vertical-align: middle
    &:first-of-type
      text-align: right
      color: gray
</style>
