<template>
  <v-card flat dark>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline text-capitalize" v-text="`${entity} popularity`" />
        <v-list-item-subtitle>
          Average number of stars for the {{ climbType }} of each {{ entity | pluralize(1) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-card-text>
      <div v-if="entity != 'setters' || gym.show_setter_popularity">
        <p v-if="!stats.length">There are no {{ entity }} stats yet...</p>
        <div class="d-flex justify-space-between">
          <span>Votes</span>
          <span>Average star rating</span>
        </div>
        <div v-for="(stat, index) in statsSorted" :key="index" class="tl-pop-stat d-flex align-center">
          <span class="tl-pop-stat__votes grey--text text-right mr-2">{{ stat.count }}</span>
          <div class="tl-pop-stat__bar-container grow d-flex">
            <div class="tl-pop-stat__bar" :style="getBarStyle(stat)" />
            <span class="tl-pop-stat__label white--text ml-2">{{ stat.label }}</span>
          </div>
          <span class="tl-pop-stat__rating ml-2">{{ stat.avg.toFixed(2) }}</span>
        </div>
      </div>
      <v-switch
        v-if="entity == 'setters'"
        v-model="showSettersPopularity"
        :loading="togglingSetters"
        :disabled="togglingSetters"
        label="Show setter popularity"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import gymStatsPageMixin from '../gym-stats-page.mixin'

export default {
  mixins: [toolbarMixin, gymStatsPageMixin],
  props: {
    entity: { type: String, default: '' },
    stats: { type: Array, default: () => [] },
    scale: { type: String, default: 'relative' },
    climbType: { type: String, default: 'boulder' },
  },
  data: () => ({
    togglingSetters: false,
  }),
  computed: {
    ...mapState(['gym']),
    statsSorted() {
      return this.stats.slice().sort((a, b) => b.avg - a.avg)
    },
    showSettersPopularity: {
      get() {
        return this.gym.show_setter_popularity
      },
      async set(newVal) {
        if (newVal) {
          let confirmed = await this.confirm({
            title: `Are you sure?`,
            text: `Enabeling this feature might change the atmosphere within your team of setters.
              This might or might not be desireable, so be sure you have considered the consequences.`,
          })
          if (!confirmed) return (this.showSettersPopularity = false)
        }
        if (newVal != this.gym.show_setter_popularity) {
          this.togglingSetters = true
          this.gym.$apiUpdate({ show_setter_popularity: newVal }).finally(() => (this.togglingSetters = false))
        }
      },
    },
  },
  methods: {
    ...mapActions('dialog', ['confirm']),
    getBarStyle(stat) {
      return {
        width: this.getWidth(stat) + '%',
        backgroundColor: this.getBackgroundColor(stat.count_rel),
      }
    },
    getBackgroundColor(countRel) {
      if (countRel == 0) return 'gray'
      return countRel < 0.25 ? `rgba(255,0,148,${countRel * 4})` : '#FF0094'
    },
    getWidth(stat) {
      // eslint-disable-next-line
      return this.scale == 'relative'
        ? (stat.avg_rel * 98) + 2
        : (stat.avg / 5 * 98) + 2
    },
  },
}
</script>

<style lang="sass">
.tl-pop-stat
  height: 30px
  &__votes
    width: 35px
  &__bar-container
    height: 100%
    position: relative
  &__bar
    margin: 1px 0
    border-radius: 0 4px 4px 0
    background-color: var(--v-primary-base)
    transition: width 0.3s
    &:hover
      opacity: 0.8
  &__label
    position: absolute
    top: 50%
    transform: translateY(-50%)
</style>
