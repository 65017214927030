<template>
  <v-card :loading="loading">
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">Usage history</v-list-item-title>
        <v-list-item-subtitle>Monthly number of users active in the gym</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-card-text>
      <span><b>How it works</b></span>
      <p>An active user is a user that logged an ascend in the month specified.</p>

      <div class="tl-usage d-flex">
        <div v-for="stat in statsPaginated" :key="stat.key" class="d-flex flex-column grow align-center justify-end">
          <div class="tl-usage__bar primary" :style="getBarStyle(stat.value)">
            <span class="tl-usage__value text--primary">{{ stat.value }}</span>
          </div>
          <span class="tl-usage__date text-no-wrap">{{ stat.date | formatDate("MMM 'YY") }}</span>
        </div>
      </div>
    </v-card-text>

    <tl-pagination-local :list="stats" @list-paginated="statsPaginated = $event" :items-per-page="12" start-at-end />
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Gym from '@/models/Gym'
import tlPaginationLocal from '@/components/shared/tl-pagination-local'

export default {
  components: {
    tlPaginationLocal,
  },
  data: () => ({
    loading: false,
    stats: [],
    statsPaginated: [],
    maxVal: 0,
  }),
  computed: {
    ...mapState(['gym']),
  },
  created() {
    this.fetch()
  },
  watch: {
    statsPaginated() {
      this.updateMaxVal()
    },
  },
  methods: {
    async fetch() {
      this.loading = true
      let statsObj = await Gym.$apiCall('activeUserCounts', { params: { gym_id: this.gym.id }, timeout: 0 }).finally(
        () => (this.loading = false)
      )
      this.stats = Object.keys(statsObj)
        .map(date => ({ date: new Date(date), key: date, value: statsObj[date] }))
        .sort((a, b) => a.date - b.date)
    },
    updateMaxVal() {
      this.maxVal = 0
      // Delay calculation for bar-grow transitions:
      this.$nextTick(() => {
        this.maxVal = Math.max.apply(
          null,
          this.statsPaginated.map(s => s.value)
        )
      })
    },
    getBarStyle(value) {
      let percentage = this.maxVal <= 0 ? 0 : (value / this.maxVal) * 100
      return { height: `${percentage}%` }
    },
  },
}
</script>

<style lang="sass">
.tl-usage
  height: 300px
  padding-top: 1em
  padding-left: 10px
  padding-bottom: 48px
  &__value
    position: absolute
    top: 0
    left: 50%
    transform: translate(-50%, -100%)
  &__bar
    position: relative
    border-radius: 4px 4px 0 0
    width: 94%
    height: 0%
    transition: height 300ms
  &__date
    width: 0
    height: 0
    transform: rotate(-45deg) translate(-3.5em, 0)
</style>
