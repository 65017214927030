<template>
  <v-card flat dark>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline" v-text="region.name || 'Graph without name'" />
        <v-list-item-subtitle>
          Concerns the climbs on {{ wallIds.length || 'all' }} {{ 'wall' | pluralize(wallIds.length) }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon :disabled="editing" @click="openEditor"><v-icon>tl-edit</v-icon></v-btn>
      </v-list-item-action>
      <v-list-item-action>
        <v-btn icon :loading="removing" :disabled="removing" @click="remove"><v-icon>tl-delete</v-icon></v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-card-text>
      <v-expand-transition>
        <v-card v-if="editing" light :loading="saving || syncingRegionWalls" class="mb-4">
          <v-card-title>Edit graph</v-card-title>
          <v-card-text>
            <v-text-field
              :value="region.name"
              label="Name"
              placeholder="E.g. 'Upstairs' or 'Outdoor'"
              @input="region.$update({ name: $event })"
            />

            <span><b>Filter walls</b></span>
            <p>Customize the walls for which climbs are included in this graph.</p>
            <v-select
              v-model="wallIdsNew"
              :items="walls"
              label="Walls"
              placeholder="All walls selected"
              multiple
              @blur="applyRegionWalls"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index <= 2">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span v-if="index == 3" class="grey--text caption">(+{{ wallIdsNew.length - 3 }} others)</span>
              </template>
            </v-select>

            <span><b>Desired counts</b></span>
            <p>
              The graph shows the number of climbs. To be able to judge this graph, you can set your own 'desired'
              counts. This represents the number of climbs you whish to have for each grade or grade-group.
              <span v-if="!anyDesired">
                The 'Add desired counts' button initializes the desired numbers by setting them equal to the current
                actual numbers. Next, you can customize them individually.
              </span>
              <span v-else>
                Below, you can customize your desired counts individually. Use the 'Grade grouping' buttons above to set
                desired counts for multiple grades that are close together.
              </span>
            </p>
            <v-btn text :loading="togglingDesireds" :disabled="togglingDesireds" @click="toggleAddDesired">
              {{ anyDesired ? 'Remove' : 'Add' }} desired counts
            </v-btn>
          </v-card-text>

          <tl-grade-dist
            :counts="region.grade_counts"
            :counts-desired="region.desired_grades"
            :level="level"
            :hide-zeros="false"
            editable
            class="mx-4 mb-4"
            @update-desired="region.$update({ desired_grades: $event })"
          />
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="cancel">Cancel</v-btn>
            <v-btn text color="primary" :disabled="saving" @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-expand-transition>
      <v-expand-transition>
        <tl-grade-dist
          v-if="!editing"
          :counts="region.grade_counts"
          :counts-desired="region.desired_grades"
          :level="level"
        />
      </v-expand-transition>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Region from '@/models/Region'
import RegionWall from '@/models/RegionWall'
import tlGradeDist from '@/components/shared/tl-grade-dist'

export default {
  components: {
    tlGradeDist,
  },
  props: {
    region: { type: Region, default: () => {} },
    level: { type: Number, default: 2 },
  },
  data: () => ({
    editing: false,
    saving: false,
    removing: false,
    togglingDesireds: false,
    syncingRegionWalls: false,
    wallIdsNew: [],
  }),
  computed: {
    ...mapState(['gym']),
    walls() {
      return this.gym.walls.map(wall => ({ text: wall.name, value: wall.id }))
    },
    wallIds() {
      return this.region.region_walls.map(regionWall => regionWall.wall_id)
    },
    anyDesired() {
      return this.region.desired_grades && this.region.desired_grades.length
    },
    wallsChanged() {
      return this.wallIdsNew == this.wallIds
    },
  },
  methods: {
    ...mapActions('dialog', ['confirm']),
    openEditor() {
      this.wallIdsNew = this.wallIds
      this.editing = true
    },
    closeEditor() {
      this.editing = false
    },
    async applyRegionWalls() {
      this.syncingRegionWalls = true
      await this.syncRegionWalls()
      await this.reloadRegion()
      this.syncingRegionWalls = false
    },
    async syncRegionWalls() {
      let dfdsRemove = this.wallIds // eslint-disable-line
        .filter(wallId => !this.wallIdsNew.includes(wallId))
        .map(this.removeRegionWall)
      let dfdsCreate = this.wallIdsNew // eslint-disable-line
        .filter(wallId => !this.wallIds.includes(wallId))
        .map(this.addRegionWall)
      return Promise.all(dfdsRemove.concat(dfdsCreate))
    },
    async addRegionWall(wallId) {
      let newRegionWall = { region_id: this.region.id, wall_id: wallId }
      return RegionWall.$apiCreate(newRegionWall, { eagerInject: true })
    },
    async removeRegionWall(wallId) {
      let regionWall = RegionWall.findBy('wall_id', wallId)
      if (!regionWall) return
      return regionWall.$apiDestroy({ eagerEject: true })
    },
    async remove() {
      let confirmed = await this.confirm({
        title: 'Are you sure?',
        text: "This will also remove all of it's desired count numbers.",
        ok: 'Remove graph',
      })
      if (!confirmed) return

      this.removing = true
      this.region // eslint-disable-line
        .$apiUpdate({ deleted: true, deleted_at: new Date() })
        .finally(() => (this.removing = false))
    },
    toggleAddDesired() {
      this.togglingDesireds = true
      let desired_grades = this.anyDesired ? [] : this.region.grade_counts
      this.region
        .$apiUpdate({ desired_grades })
        .then(() => this.$emit('changed'))
        .finally(() => (this.togglingDesireds = false))
    },
    reloadRegion() {
      return Region.$apiFind(this.region.id, { params: { gym_id: this.gym.id }, include_grades: true }).then(() =>
        this.$emit('changed')
      )
    },
    cancel() {
      this.region.$restore()
      this.closeEditor()
    },
    save() {
      this.saving = true
      Promise.all([this.syncRegionWalls(), this.region.$apiSave()])
        .then(() => {
          this.$emit('changed')
          this.closeEditor()
        })
        .finally(() => (this.saving = false))
    },
  },
}
</script>
